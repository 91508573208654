import React from "react"
import { graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { format } from "date-fns"
import PropTypes from "prop-types"
import theme from "../utils/theme"
import * as S from "./styles/__challenge-calendar.styles"
import {
  Layout,
  TemplateHeading,
  EventCard,
  TextButton,
  Download,
} from "../components"

const ChallengeCalendar = ({ data, location }) => {
  const latestChallenge = data.allContentfulChallenge.nodes[0]
  return (
    <Layout>
      <ThemeProvider theme={{ ...theme }}>
        {latestChallenge && (
          <S.Grid>
            <TemplateHeading
              location={location}
              breadcrumbLabel="Calendar"
              title="Challenge Calendar"
              desc={latestChallenge.pageDescription.pageDescription}
              parentContext={{
                parentLabel: "Challenge",
                childLabel: "Calendar",
                location,
              }}
            />
            {latestChallenge.programCalendar && (
              <Download
                url={latestChallenge.programCalendar.file.url}
                fileName={latestChallenge.programCalendar.file.fileName}
                label="Download PDF"
                fileSize={latestChallenge.programCalendar.file.details.size}
              />
            )}
            {latestChallenge?.keyDates?.map((keydate, i) => {
              return (
                <>
                  <S.Date key={`Date ${i}`}>
                    {format(new Date(keydate.date), "dd.MM.yyyy")}
                  </S.Date>
                  <S.EventWrap>
                    <EventCard
                      key={`Card ${i}`}
                      title={keydate.title}
                      desc={keydate.description.description}
                    />
                    {keydate.event && (
                      <TextButton
                        label="More information"
                        link={`events/${keydate.event.slug}`}
                      />
                    )}
                  </S.EventWrap>
                </>
              )
            })}
          </S.Grid>
        )}
      </ThemeProvider>
    </Layout>
  )
}
ChallengeCalendar.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    allContentfulChallenge: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default ChallengeCalendar

export const pageQuery = graphql`
  query {
    allContentfulChallenge {
      nodes {
        year
        themeName
        pageDescription {
          pageDescription
        }
        programCalendar {
          file {
            url
            fileName
            details {
              size
            }
          }
        }
        keyDates {
          title
          date
          event {
            slug
          }
          description {
            description
          }
        }
      }
    }
  }
`
