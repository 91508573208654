import media from "styled-media-query"
import styled from "styled-components"
import { TemplateHeading, TextButton, EventCard, Download} from "../../components"

export const Date = styled.p`
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${props => props.theme.text02}
`
export const EventWrap = styled.div``
export const Grid = styled.div`
  display: grid;
  padding-top: 2.5rem;
  ${TemplateHeading}, ${Date}, ${Download} {
    padding: 1rem;
  }
  ${EventWrap}, ${Download} {
    padding-bottom: 2.5rem;
  }
  ${Date} {
    border-top: ${props => props.theme.borderStyle};
    padding-top: 1.5rem;
  }
  ${TextButton} {
    padding-top: 1.6rem;
  }
  ${EventWrap} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  ${EventCard} {
    h1, h2 {
      margin: 0rem;
      font-size: 2rem;
    }
    p {
      padding-top: 1rem;
    }
    height: auto;
    padding: 0rem;
    div:first-of-type {
      position: relative;
    }
  }
  ${media.greaterThan("medium")`
    /* screen width is less than 768px (medium) */
    grid-template-columns: 1fr 480px 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    padding-top: 4rem;
    padding-bottom: 3rem;
    ${TemplateHeading}, ${EventWrap}, ${Date}, ${Download}, ${TextButton} {
      padding-left: 0rem;
      padding-right: 0rem;
    }
    ${EventWrap}, ${Date} {
      border-top: ${props => props.theme.borderStyle};
      &:last-of-type {
        border-bottom: ${props => props.theme.borderStyle};
      }
    }
    ${EventWrap}  {
      grid-column: 2 / end;
      padding: 2rem 0rem 4rem 0rem;
      > * {
        max-width: 62.5%;
      }
      p {
        padding-right: 0rem;
      }
    }
    ${Download} {
      grid-column: 2 / end;
      padding: 1.5rem 0rem 4rem 0rem;
    }
    ${TemplateHeading} {
      padding-bottom: 0rem;
      grid-column: 2 / end;
      > * {
        max-width: 62.5%;
      }
      justify-items: flex-start;
      text-align: left;
      p {
        margin-top: 1rem;
      }
    }
    ${Date} {
      grid-column: 1 / span 1;
      padding: 2rem;
    }
  `}
  ${media.between("medium","1055px")`
    ${TemplateHeading}, ${EventWrap} {
      padding-right: 2rem;
      > * {
        max-width: 100%;
      }
    }
  `}
`
